<template>
  <v-card flat v-if="ap && apId !== ''">
    <v-toolbar flat dark color="color_dialog">
      <v-btn color="color_action" icon @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("ap.edit.title") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn text @click="traite()" color="color_action" class="mr-5">
          <span v-if="ap.is_vue"> {{ $t("btn.traite") }} </span>
          <span v-else>
            {{ $t("btn.untraite") }}
          </span>
        </v-btn>

        <v-btn text @click="take()" color="color_action">
          <span v-if="ap.user"> {{ $t("btn.takenby") }} {{ ap.user }} </span>
          <span v-else>
            {{ $t("btn.take") }}
          </span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <ap-show :ap="ap" />
    </v-card-text>
  </v-card>
</template>

<script>
import { apMixin } from "@/mixins/ap";
import apShow from "@/components/ap/Show";
import { userMixin } from "@/mixins/user";
import { relanceMixin } from "@/mixins/relance";
import { sanitizedTextContent } from "@/lib/common";

export default {
  name: "ApEdit",
  mixins: [apMixin, userMixin, relanceMixin],
  components: {
    apShow,
  },
  data() {
    return {
      form: { typeRelance: null, commentaire: "" },
      sanitizedTextContent: sanitizedTextContent,
    };
  },
  props: {
    closeDialog: {
      type: Function,
      default: null,
    },
    apId: {
      type: String,
      default: "",
    },
  },
  methods: {
    take() {
      this.takeAp({
        id: this.apId,
        list: this.items,
        item: this.ap,
      });
    },
    traite() {
      this.traiteAp({
        id: this.apId,
        list: this.items,
        item: this.ap,
      });
    },
    saveCommentaire() {
      this.createRelance({
        id: this.apId,
        list: this.ap.relances,
        user_name: this.user.first_name + " " + this.user.last_name,
        values: this.form,
      });
    },
  },
  watch: {
    apId(apId) {
      this.getAp(apId);
    },
  },
  mounted() {
    this.getAp(this.apId);
  },
};
</script>

<style scoped></style>
