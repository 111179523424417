<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-file-input
          v-if="item.sta"
          v-model="files"
          chips
          counter
          multiple
          show-size
          truncate-length="30"
          append-outer-icon="mdi-plus-circle-outline"
        >
          <template v-slot:append-outer>
            <v-btn
              fab
              color="color_action"
              @click="addFile()"
              style="top: -30px"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn
              fab
              right
              color="color_action"
              style="top: -30px"
              class="ml-2"
              @click="delFile = !delFile"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
        </v-file-input>
      </v-col>
    </v-row>
    <v-row>
      <v-chip
        v-for="(file, index) in myfiles"
        :key="index"
        class="ma-2 pa-3"
        color="teal"
        large
        outlined
      >
        <a v-if="file.id !== undefined" class="v-btn" :href="showFile(file)">
          <v-icon>mdi-upload</v-icon>
        </a>
        {{ file.name }}
        <v-btn v-if="delFile" icon @click="removeFile(index, file)">
          <v-icon>mdi-close-outline</v-icon>
        </v-btn>
      </v-chip>
    </v-row>
  </v-container>
</template>

<script>
import { cockpitMixin } from "@/mixins/cockpit";
const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

export default {
  name: "CockpitFile",
  mixins: [cockpitMixin],
  data() {
    return {
      files: [],
      myfiles: [],
      delFile: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    addFile() {
      this.myfiles = [...this.files, ...this.myfiles];
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files_" + i, this.files[i]);
      }
      this.ajouteFile({ id: this.item.id, formData: formData });
      this.files = [];
    },
    showFile(file) {
      return URL_BACKEND + "api-cockpit/showFile/" + file.id + "/";
    },
    removeFile(index, file) {
      this.supFile({ id: file.id });
      this.myfiles.splice(index, 1);
    },
  },
  watch: {
    item() {
      this.myfiles = this.item.FileCockpits;
      this.files = [];
      this.delFile = false;
    },
  },
  mounted() {
    this.myfiles = this.item.FileCockpits;
  },
};
</script>

<style scoped>
.v-chip__close.v-icon {
  font-size: 18px;
  max-height: 18px;
  max-width: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: red !important;
}
</style>
