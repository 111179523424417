<template>
  <v-card flat v-if="user && user.is_option_dr">
    <v-card-title>{{ $t("dr.options") }}</v-card-title>
    <v-card flat>
      <v-card-text>
        <v-data-table
          :items="drs"
          :headers="headers"
          item-key="id"
          @dblclick:row="update"
        >
          <template #[`item.is_decompose_gestionnaire`]="{ item }">
            <v-icon v-if="item.is_decompose_gestionnaire" color="green"
              >mdi-check-circle-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" class="pa-4">
            <v-checkbox
              v-model="form.is_decompose_gestionnaire"
              :label="$t('dr.is_decompose_gestionnaire')"
            />
            <v-text-field
              type="number"
              v-model="form.max_date_allocation"
              :label="$t('dr.max_date_allocation')"
            />
            <v-text-field
              type="number"
              v-model="form.alert_nb_jour"
              :label="$t('dr.alert_nb_jour')"
            />
            <v-text-field
              type="number"
              v-model="form.warnings_nb_jour"
              :label="$t('dr.warnings_nb_jour')"
            />
            <v-select
              v-model="form.default_statut_cockpit"
              :items="items_statut_cockpit"
              multiple
              chips
              deletable-chips
              :label="$t('dr.default_statut_cockpit')"
            />
            <v-select
              v-model="form.default_statut_ap"
              :items="items_statut_ap"
              multiple
              chips
              deletable-chips
              :label="$t('dr.default_statut_ap')"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="color_action" @click="dialog = false">
            <v-icon left> mdi-close</v-icon>
            {{ $t("btn.close") }}
          </v-btn>
          <v-spacer />
          <v-btn :disabled="!valid" color="color_action" @click="save">
            <v-icon left>mdi-send</v-icon>
            {{ $t("btn.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { drMixin } from "@/mixins/dr";
import { userMixin } from "@/mixins/user";

export default {
  name: "DrList",
  mixins: [drMixin, userMixin],
  data() {
    return {
      dialog: false,
      search: "",
      valid: false,
      item: null,
      nouveau: false,
      form: {
        max_date_allocation: null,
        is_decompose_gestionnaire: true,
        default_statut_ap: [],
        default_statut_cockpit: [],
        alert_nb_jour: null,
        warnings_nb_jour: null,
      },
      items_statut_cockpit: [
        "F.Dem. A revalider",
        "F.Dem. Invalide",
        "F.Dem. Rejetée",
        "F.Dem. A recycler CPP",
        "F.Dem. Suspendue",
        "Erreur à l'import AP",
      ],
      items_statut_ap: [
        "Facture AP jamais validée",
        "Facture AP rejetée dossier",
        "Facture AP à revalider",
        "Facture AP avec blocages",
        "Facture AP bloquée dossier",
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("dr.nom"),
          align: "start",
          sortable: true,
          value: "libelle",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("dr.max_date_allocation"),
          align: "start",
          sortable: true,
          value: "max_date_allocation",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("dr.is_decompose_gestionnaire"),
          align: "start",
          sortable: true,
          value: "is_decompose_gestionnaire",
          class: "color_thead  white--text",
        },
      ];
    },
  },
  methods: {
    update(event, { item }) {
      this.nouveau = false;
      this.item = item;
      this.form = {
        max_date_allocation: item.max_date_allocation,
        is_decompose_gestionnaire: item.is_decompose_gestionnaire,
        default_statut_ap: item.default_statut_ap,
        default_statut_cockpit: item.default_statut_cockpit,
        alert_nb_jour: item.alert_nb_jour,
        warnings_nb_jour: item.warnings_nb_jour,
      };
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    save() {
      this.dialog = false;
      this.updateDr({ id: this.item.id, values: this.form, item: this.item });
    },
  },
};
</script>

<style scoped></style>
