<template>
  <v-card flat>
    <v-overlay :value="isLoading()">
      <v-progress-circular indeterminate size="100" />
    </v-overlay>
    <v-card-text>
      <cockpit-list :cockpits="items" />
    </v-card-text>
  </v-card>
</template>

<script>
import { cockpitMixin } from "@/mixins/cockpit";
import cockpitList from "@/components/cockpit/List";
import { userMixin } from "@/mixins/user";
import { relanceMixin } from "@/mixins/relance";

export default {
  name: "VueCockpit",
  mixins: [cockpitMixin, userMixin, relanceMixin],
  components: {
    cockpitList,
  },
  methods: {
    isLoading() {
      return (
        this.cockpitsIsLoading ||
        this.cockpitIsLoading ||
        this.usersIsLoading ||
        this.relanceTypesIsLoading ||
        this.relanceIsLoading ||
        this.ajouteFileIsLoading
      );
    },
  },
  mounted() {
    if (this.items.length === 0) this.getCockpits();
    this.getUsers();
    this.getTypeRelances();
  },
};
</script>

<style scoped></style>
