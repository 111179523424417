<template>
  <v-card flat>
    <v-card flat>
      <v-card-title>
        {{ $t("ap.title") }}
        <v-btn color="color_action" @click="onexport()" class="ml-3">
          export excel
        </v-btn>
        <v-spacer />
        <v-btn
          color="color_action"
          @click="
            initSearch();
            factureSearch = '';
          "
        >
          <v-icon left>mdi-eraser</v-icon>
          {{ $t("btn.clean") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="itemsFiltered()"
          :headers="headers"
          item-key="id"
          :item-class="itemRowBackground"
          @dblclick:row="openDialog"
          fixed-header
          height="calc(100vh - 200px)"
        >
          <template #[`top`]>
            <v-row>
              <v-checkbox
                v-model="itemSearch.old"
                :label="$t('ap.old')"
                class="mb-2"
              />
              <v-checkbox
                v-model="itemSearch.ged"
                :label="$t('ap.ged')"
                class="mb-2 ml-2"
              />
              <template v-if="user.is_gestionnaire">
                <v-spacer />
                <v-autocomplete
                  v-model="gestionnaires"
                  :items="users"
                  item-text="email"
                  item-value="id"
                  small-chips
                  multiple
                  :label="$t('sb.gestionnaire')"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            gestionnaires?.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-if="gestionnaires?.length === users.length"
                          >Aucun
                        </v-list-item-title>
                        <v-list-item-title v-else>Tous</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
                <v-btn icon x-large color="color_action" @click="chargeUser()">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <v-spacer />
              <v-text-field
                v-model="factureSearch"
                :label="$t('ap.searchfacture')"
              />
              <v-btn
                x-large
                icon
                color="color_action"
                @click="searchFacture()"
                class="ml-3"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-row>
          </template>
          <template #[`body.prepend`]>
            <tr class="grey lighten-4">
              <td>
                <v-autocomplete
                  v-model="itemSearch.dr"
                  type="text"
                  :items="ListeDr()"
                />
              </td>
              <td>
                <v-autocomplete
                  v-model="itemSearch.sb"
                  type="text"
                  :items="ListeSb()"
                />
              </td>
              <td>
                <v-autocomplete
                  v-model="itemSearch.compte_comptable"
                  type="text"
                  :items="Listecompte_comptable()"
                />
              </td>
              <td>
                <v-text-field
                  v-model="itemSearch.raison_social_four"
                  type="text"
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.statut_fact.ap"
                  multiple
                  chips
                  deletable-chips
                  :items="ListeStatutFac()"
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.type_fact"
                  type="text"
                  :items="ListeTypeFac()"
                />
              </td>
              <td>
                <v-text-field v-model="itemSearch.numero_facture" type="text" />
              </td>
              <td>
                <v-text-field
                  v-model="itemSearch.montant_ht_min"
                  type="number"
                  label="Montant min"
                />
                <v-text-field
                  v-model="itemSearch.montant_ht_max"
                  type="number"
                  label="Montant max"
                />
              </td>
              <td>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="itemSearch.date_reception_fact"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="itemSearch.date_reception_fact"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="itemSearch.date_reception_fact"
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Annuler
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(itemSearch.date_reception_fact)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </td>
              <td>
                <v-text-field v-model="itemSearch.commande" type="text" />
              </td>

              <td>
                <v-text-field v-model="itemSearch.acheteur" type="text" />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.contrat_dates"
                  :items="ListeOuiNon"
                />
              </td>
              <td>
                <v-text-field
                  v-model="itemSearch.maxInteret"
                  type="text"
                  label="Plus de n jours"
                />
                <v-select
                  v-model="itemSearch.alerte"
                  :items="ListeTypeAlerte"
                />
              </td>
              <td>
                <v-text-field
                  v-model="itemSearch.responsable_action"
                  type="text"
                />
              </td>
              <td>
                <v-select v-model="itemSearch.user" :items="ListeUser()" />
              </td>
            </tr>
          </template>
          <template #[`item.interet`]="{ item }">
            <v-chip
              :class="
                getColorInteret(item.interet, item.dr, {
                  DANGER: user.alert_nb_jour,
                  WARNING: user.warnings_nb_jour,
                  DEAD: user.im_nb_jour,
                })
              "
              class="ma-2"
            >
              {{ item.interet }}
            </v-chip>
          </template>
          <template #[`item.alerte_alloc_commande`]="{ item }">
            <v-icon v-if="item.alerte_alloc_commande === 3" color="indigo">
              mdi-clock-fast
            </v-icon>
            <v-icon v-if="item.alerte_alloc_commande === 2" color="red ">
              mdi-clock-end
            </v-icon>
            <v-icon v-if="item.alerte_alloc_commande === 1" color="green ">
              mdi-clock-outline
            </v-icon>
            <v-icon v-if="item.alerte_alloc_commande === 0" color="teal ">
              mdi-all-inclusive
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        persistent
      >
        <ap-edit :apId="itemId" :close-dialog="closeDialog" />
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
import apEdit from "@/components/ap/Edit";
import { apMixin } from "@/mixins/ap";
import { userMixin } from "@/mixins/user";
import { listMixins } from "@/mixins/listMixins";
import { getFiltered } from "@/lib/libList";
import { utils, writeFile } from "xlsx";

export default {
  name: "ApList",
  components: {
    apEdit,
  },
  mixins: [apMixin, userMixin, listMixins],
  data() {
    return {
      type: "AP",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("cockpit.dr"),
          align: "start",
          sortable: true,
          value: "dr",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.sb"),
          align: "start",
          sortable: true,
          value: "sb",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.compte_comptable"),
          align: "start",
          sortable: true,
          value: "compte_comptable",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.raison_social_four"),
          align: "start",
          sortable: true,
          value: "raison_social_four",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.statut_fact"),
          align: "start",
          sortable: true,
          value: "statut_fact",
          class: "color_thead   white--text",
        },
        {
          text: this.$t("cockpit.type_fact"),
          align: "start",
          sortable: true,
          value: "type_fact",
          class: "color_thead   white--text",
        },
        {
          text: this.$t("cockpit.numero_facture"),
          align: "start",
          sortable: true,
          value: "numero_facture",
          class: "color_thead white--text",
        },
        {
          text: this.$t("cockpit.montant_ht"),
          align: "start",
          sortable: true,
          value: "montant_ht",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.date_reception_fact"),
          align: "start",
          sortable: true,
          value: "date_reception_fact",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.commande"),
          align: "start",
          sortable: true,
          value: "commande",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.acheteur"),
          align: "start",
          sortable: true,
          value: "acheteur",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("ap.datelimit"),
          align: "start",
          sortable: true,
          value: "alerte_alloc_commande",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.interet"),
          align: "start",
          sortable: true,
          value: "interet",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.responsable_action"),
          align: "start",
          sortable: true,
          value: "responsable_action",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("cockpit.user"),
          align: "start",
          sortable: true,
          value: "user",
          class: "color_thead  white--text",
        },
      ];
    },
  },
  methods: {
    staFact() {
      if (
        this.user.default_statut_ap === null ||
        this.user.default_statut_ap.length > 1
      )
        return [];
      return this.user.default_statut_ap[0].flat();
    },
    chargeUser() {
      this.getAps(this.gestionnaires);
    },
    onexport() {
      let json = [];
      const items = getFiltered(this.items, this.itemSearch, "AP", {
        DANGER: this.user.alert_nb_jour,
        WARNING: this.user.warnings_nb_jour,
        DEAD: this.user.im_nb_jour,
      });
      Object.keys(items).forEach((key) => {
        json.push({
          Dr: items[key].dr,
          Structure: items[key].sb,
          Fournisseur: items[key].raison_social_four,
          "Statut facture": items[key].statut_fact,
          "Type facture": items[key].type_fact,
          "Numéro de facture": items[key].numero_facture,
          "Montant HT facture": items[key].montant_ht,
          "Date réception de la facture": items[key].date_reception_fact,
          "N° Commande": items[key].commande,
          Acheteur: items[key].acheteur,
          "Alerte fin de contrat / sans commande":
            items[key].alerte_alloc_commande,
          "Délai de paiement (nb de jours)": items[key].interet,
          "Suivi par": items[key].responsable_action,
          "Traitée dans SUIGE par": items[key].user,
        });
      });
      let ws = utils.json_to_sheet(json);
      let wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Factures Aps");
      writeFile(wb, "ap.xlsx");
    },
    searchFacture() {
      this.initSearch();
      if (this.factureSearch !== "")
        this.getFacturesAp({ search: this.factureSearch });
      else {
        this.getFacturesAp();
      }
      this.factureSearch = "";
    },
  },
};
</script>

<style scoped>
.deja_fait {
  font-size: 16px !important;
  color: #0d47a1 !important;
}
</style>
