import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import router from "./router";
import Vuex from "vuex";
import VueCookies from "vue-cookies";

import user from "@/store/user";
import dr from "@/store/dr";
import sb from "@/store/sb";
import ap from "@/store/ap";
import cockpit from "@/store/cockpit";
import commande from "@/store/commande";
import contrat from "@/store/contrat";
import typeRelance from "@/store/typeRelance";
import relance from "@/store/relance";

Vue.config.productionTip = false;

Vue.use(Vuex);

Vue.use(VueCookies);
Vue.$cookies.config("30d");

const store = new Vuex.Store({
  modules: {
    user,
    dr,
    sb,
    ap,
    contrat,
    commande,
    cockpit,
    typeRelance,
    relance,
  },
});

new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
