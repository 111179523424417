import * as types from "./mutation_types";

export default {
  [types.COCKPIT_RESET](state) {
    Object.assign(state, {
      error: "",
      isLoading: false,
      items: null,
      previous: null,
      next: null,
    });
  },

  [types.COCKPIT_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.COCKPIT_SET_ITEMS](state, items) {
    Object.assign(state, { items });
  },

  [types.COCKPIT_TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
