import { mapActions, mapGetters } from "vuex";

export const sbMixin = {
  computed: {
    ...mapGetters({
      sbs: "sb/list/items",
      sbIsLoading: "sb/list/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getSbs: "sb/list/getItems",
      updateSb: "sb/update/update",
    }),
  },
};
