import current from "./current";
import listEmail from "./listEmail";
import list from "./list";
import listAll from "./listAll";
import update from "./update";
import create from "./create";

export default {
  namespaced: true,
  modules: {
    current,
    listEmail,
    list,
    listAll,
    update,
    create,
  },
};
