const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";
import * as types from "./mutation_types";

export const retrieve = ({ commit }, apId) => {
  commit(types.AP_SHOW_TOGGLE_LOADING);
  axios
    .get(URL_BACKEND + "api-ap/" + apId)
    .then((response) => {
      commit(types.AP_SHOW_TOGGLE_LOADING);
      commit(types.AP_SHOW_SET_RETRIEVED, response.data);
    })
    .catch((e) => {
      commit(types.AP_SHOW_TOGGLE_LOADING);
      commit(types.AP_SHOW_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.AP_SHOW_RESET);
};
