import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.COCKPIT_UPDATE_TRAITE_RESET);
};

export const update = ({ commit }, values) => {
  commit(types.COCKPIT_UPDATE_TRAITE_SET_ERROR, "");
  commit(types.COCKPIT_UPDATE_TRAITE_TOGGLE_LOADING);
  axios
    .put(URL_BACKEND + "api-cockpit/traite/" + values.id + "/")
    .then((response) => {
      commit(types.COCKPIT_UPDATE_TRAITE_SET_UPDATED, true);
      commit(types.COCKPIT_UPDATE_TRAITE_TOGGLE_LOADING);
      const index = values.list.findIndex((value) => value.id === values.id);
      if (index > -1) {
        values.list[index].is_vue = response.data.is_vue;
        values.item.is_vue = response.data.is_vue;
      }
    })
    .catch((e) => {
      commit(types.COCKPIT_UPDATE_TRAITE_TOGGLE_LOADING);
      commit(types.COCKPIT_UPDATE_TRAITE_SET_ERROR, e.message);
    });
};
