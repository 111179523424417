import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Login from "@/views/Login";
import Logout from "@/views/Logout";
import Ap from "@/views/Ap";
import Cockpit from "@/views/Cockpit";
import Structure from "@/views/Structure";
import Dr from "@/views/Dr";
import User from "@/views/User";

Vue.use(VueRouter);

const routes = [
  {
    path: "/suige",
    name: "home",
    component: Home,
  },
  {
    path: "/suige/login",
    name: "login",
    component: Login,
  },
  {
    path: "/suige/logout",
    name: "logout",
    component: Logout,
  },
  {
    path: "/suige/ap",
    name: "ap",
    component: Ap,
  },
  {
    path: "/suige/cockpit",
    name: "cockpit",
    component: Cockpit,
  },
  {
    path: "/suige/sb",
    name: "sb",
    component: Structure,
  },
  {
    path: "/suige/dr",
    name: "dr",
    component: Dr,
  },
  {
    path: "/suige/user",
    name: "user",
    component: User,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
