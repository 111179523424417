<template>
  <v-card flat>
    <v-card flat>
      <v-card-text>
        <v-data-table
          :items="itemFiter()"
          :headers="headers"
          item-key="id"
          :search="search"
        >
          <template #[`top`]>
            <v-text-field
              v-model="search"
              cockpitpend-icon="mdi-magnify"
              label="Rechercher"
              single-line
              class="mb-1"
              clearable
            />
          </template>
          <template #[`body.prepend`]>
            <v-btn
              color="color_action"
              fab
              absolute
              icon
              right
              small
              @click="initSearch()"
            >
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
            <tr class="grey lighten-4">
              <td>
                <v-select
                  v-model="itemSearch.dr"
                  item-value="value"
                  item-text="text"
                  :items="ListeDr()"
                  chips
                  deletable-chips
                  multiple
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.sb"
                  item-value="value"
                  item-text="text"
                  multiple
                  chips
                  deletable-chips
                  :items="ListeSb()"
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.gestDr"
                  item-value="value"
                  item-text="text"
                  :items="ListeGestDr()"
                  chips
                  deletable-chips
                  multiple
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.gestSb"
                  item-value="value"
                  item-text="text"
                  :items="ListeGestSb()"
                  chips
                  deletable-chips
                  multiple
                />
              </td>
            </tr>
          </template>
          <template #[`item.gestionsb`]="{ item }">
            {{ getName(item.customUsers, false) }}
          </template>
          <template #[`item.gestiondr`]="{ item }">
            {{ getName(item.customUsers, true) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("sb.edit") }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-autocomplete
              v-model="form.gestionnaire_drs"
              :items="usersEmail"
              item-value="id"
              chips
              multiple
              deletable-chips
              item-text="email"
              :label="$t('sb.gestionnaire_drs')"
            />
            <v-autocomplete
              v-model="form.gestionnaire_labos"
              :items="usersEmail"
              item-value="id"
              chips
              multiple
              deletable-chips
              item-text="email"
              :label="$t('sb.gestionnaire_labos')"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="color_action" dark @click="dialog = false">
            <v-icon left dark> mdi-close</v-icon>
            {{ $t("btn.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="!valid"
            dark
            color="color_action"
            @click="closeDialog"
          >
            <v-icon left dark> mdi-send</v-icon>
            {{ $t("btn.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { sbMixin } from "@/mixins/sb";
import { userMixin } from "@/mixins/user";

export default {
  name: "SbList",
  mixins: [sbMixin, userMixin],
  data() {
    return {
      dialog: false,
      search: "",
      valid: false,
      item: null,
      form: {
        gestionnaire_drs: [],
        gestionnaire_labos: [],
      },
      itemSearch: {
        sb: [],
        dr: [],
        gestDr: [],
        gestSb: [],
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("sb.dr"),
          align: "start",
          sortable: true,
          value: "dr",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("sb.libelle"),
          align: "start",
          sortable: true,
          value: "libelle",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("sb.gestionnaire_drs"),
          align: "start",
          sortable: true,
          value: "gestiondr",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("sb.gestionnaire_labos"),
          align: "start",
          sortable: true,
          value: "gestionsb",
          class: "color_thead  white--text",
        },
      ];
    },
  },
  methods: {
    itemFiter() {
      if (this.sbs.length > 0) {
        let items = this.sbs;
        const search = this.itemSearch;
        if (this.itemSearch.sb.length > 0)
          items = items.filter((x) => search.sb.includes(x.libelle));
        if (this.itemSearch.dr.length > 0)
          items = items.filter((x) => search.dr.includes(x.dr));
        if (this.itemSearch.gestDr.length > 0) {
          items = items.filter(
            (x) =>
              x.customUsers.filter(
                (z) => z.is_dr && search.gestDr.includes(z.email)
              ).length > 0
          );
        }
        if (this.itemSearch.gestSb.length > 0) {
          items = items.filter(
            (x) =>
              x.customUsers.filter(
                (z) => !z.is_dr && search.gestSb.includes(z.email)
              ).length > 0
          );
        }
        return items;
      }
      return [];
    },
    ListeDr() {
      return this.sbs.map((x) => x.dr);
    },
    ListeSb() {
      return this.sbs.map((x) => x.libelle);
    },
    ListeGestDr() {
      let res = [];
      this.sbs.forEach(function (item) {
        item.customUsers.forEach(function (x) {
          if (x.is_dr && res.indexOf(x.email) === -1) res.push(x.email);
        });
      });
      return res;
    },
    ListeGestSb() {
      let res = [];
      this.sbs.forEach(function (item) {
        item.customUsers.forEach(function (x) {
          if (!x.is_dr && res.indexOf(x.email) === -1) res.push(x.email);
        });
      });
      return res;
    },
    initSearch() {
      this.itemSearch = { ...{ sb: [], dr: [], gestDr: [], gestSb: [] } };
    },
    getName(items, is_dr) {
      let res = [];
      items.forEach(function (item) {
        if (item.is_dr === is_dr) res.push(item.email);
      });
      return res.join(",");
    },
    reset() {
      this.form.gestionnaire_labos = [];
      this.form.gestionnaire_drs = [];
      this.dialog = false;
      this.item = null;
    },
    openDialog(event, { item }) {
      this.item = item;
      this.form.gestionnaire_labos = this.item.gestionnaire_labos.map(
        (x) => x.id
      );
      this.form.gestionnaire_drs = this.item.gestionnaire_drs.map((x) => x.id);
      this.dialog = true;
    },
    closeDialog() {
      this.updateSb({
        users: this.usersEmail,
        sbs: this.sbs,
        id: this.item.id,
        values: this.form,
      });
      this.item.gestionnaire_labos = this.form.gestionnaire_labos;
      this.item.gestionnaire_drs = this.form.gestionnaire_drs;
      this.reset();
    },
  },
};
</script>

<style scoped></style>
