<template>
  <v-col sm="12" :md="nbCol" v-if="elem">
    <v-row
      v-for="key in keys_col"
      :key="key.value"
      :class="key.class"
      class="pa-3"
    >
      <span class="font-weight-bold indigo--text">{{ key.text }}</span>
      <v-spacer />
      <span class="mr-6">{{ elem[key.value] }}</span>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "CommonShowCol",

  props: {
    nbCol: {
      type: Number,
      default: 6,
    },
    keys_col: {
      type: Array,
      default: null,
    },
    elem: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
