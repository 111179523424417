import { mapActions, mapGetters } from "vuex";

export const drMixin = {
  computed: {
    ...mapGetters({
      drs: "dr/list/items",
      drIsLoading: "dr/list/isLoading",
      updateDrIsLoading: "dr/update/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getDrs: "dr/list/getItems",
      updateDr: "dr/update/update",
    }),
  },
};
