import list from "./list";
import update from "./update";

export default {
  namespaced: true,
  modules: {
    list,
    update,
  },
};
