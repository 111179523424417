function f_required(value) {
  return !!value || value === 0;
}

function f_requiredSelect(value) {
  return !!value && value.length !== 0;
}

function f_counter(value, val) {
  return value && value.length <= val;
}

function f_email(value) {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (
    !f_required(value) || (pattern.test(value) && value.endsWith("@inserm.fr"))
  );
}

function f_int(value) {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^\d+$/;
  // eslint-disable-next-line no-console
  return value === undefined || value === null || pattern.test(value);
}

function f_float(value) {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^[-+]?\d+\.\d+$/;
  return !f_required(value) || f_int(value) || pattern.test(value);
}

function f_tel(value) {
  // eslint-disable-next-line no-useless-escape
  const pattern = /^\+33\s\(0\)\s*\d{1}\s*\d{2}\s*\d{2}\s*\d{2}\s*\d{2}\s*$/;
  return !f_required(value) || pattern.test(value);
}

function f_great_than(value, value1) {
  // eslint-disable-next-line no-console
  return (
    value === undefined ||
    value === null ||
    value1 === undefined ||
    value1 === null ||
    value > value1
  );
}

function f_less_than(value, value1) {
  // eslint-disable-next-line no-console
  return (
    value === undefined ||
    value === null ||
    value1 === undefined ||
    value1 === null ||
    value < value1
  );
}

export const validrules = {
  required: function (value) {
    return f_required(value) || "Ce champ ne peux pas etre vide";
  },
  requiredBool: function (value) {
    return f_required(value) || false;
  },
  requiredSelect: function (value) {
    return f_requiredSelect(value) || "Ce champ ne peux pas etre vide";
  },
  requiredSelectBool: function (value) {
    return f_requiredSelect(value) || false;
  },
  counter: function (value, val) {
    return f_counter(value, val) || "Trop de caractere.";
  },
  email: function (value) {
    return f_email(value) || "Mauvais format (mail en @inserm.fr obligatoire).";
  },
  float: function (value) {
    return value === "" || f_float(value) || "Mauvais format";
  },
  int: function (value) {
    return value === "" || f_int(value) || "Mauvais format";
  },
  tel: function (value) {
    return f_tel(value) || "Mauvais format : +33 (0) X XX XX XX XX";
  },
  great_than: function (value, value1) {
    return value === "" || f_great_than(value, value1) || "Mauvaises valeurs";
  },
  less_than: function (value, value1) {
    return value === "" || f_less_than(value, value1) || "Mauvaises valeurs";
  },
};

export const validIcon = {
  required: function (value) {
    return f_required(value);
  },

  requiredSelect: function (value) {
    return f_requiredSelect(value);
  },
  counter: function (value, val) {
    return f_counter(value, val);
  },
  email: function (value) {
    return f_email(value);
  },
  float: function (value) {
    return f_float(value);
  },
  int: function (value) {
    return f_int(value);
  },
  tel: function (value) {
    return f_tel(value);
  },
  bool: function (value) {
    return value !== null;
  },
  great_than: function (value, value1) {
    return f_great_than(value, value1);
  },
};

export const putSta = function (val) {
  return !val ? "B" : "V";
};
