import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.FILE_RESET);
};

export const add = ({ commit }, values) => {
  commit(types.FILE_TOGGLE_LOADING);
  axios
    .put(
      URL_BACKEND + "api-cockpit/addFile/" + values.id + "/",
      values.formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(function () {
      commit(types.FILE_SET_UPDATED, true);
      commit(types.FILE_TOGGLE_LOADING);
    })
    .catch(function () {
      commit(types.FILE_TOGGLE_LOADING);
    });
};

export const remove = ({ commit }, values) => {
  commit(types.FILE_TOGGLE_LOADING);
  axios
    .put(URL_BACKEND + "api-cockpit/removeFile/" + values.id + "/")
    .then(function () {
      commit(types.FILE_SET_UPDATED, true);
      commit(types.FILE_TOGGLE_LOADING);
    })
    .catch(function () {
      commit(types.FILE_TOGGLE_LOADING);
    });
};
