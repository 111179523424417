import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.USER_CREATE_RESET);
};

export const create = ({ commit, dispatch }, values) => {
  commit(types.USER_CREATE_SET_ERROR, "");
  commit(types.USER_CREATE_TOGGLE_LOADING);
  axios
    .post(URL_BACKEND + "api-user/create/", values.values)
    .then((response) => {
      commit(types.USER_CREATE_TOGGLE_LOADING);
      if (response !== undefined) {
        commit(types.USER_CREATE_SET_CREATED, true);
        values.list.push(response.data);
      } else {
        commit(types.USER_CREATE_SET_ERROR, "Erreur");
        dispatch("user/current/retrieve", {}, { root: true });
      }
    })
    .catch((e) => {
      commit(types.USER_CREATE_TOGGLE_LOADING);
      commit(types.USER_CREATE_SET_ERROR, e.message);
    });
};
