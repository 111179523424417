<template>
  <v-container>
    <v-alert outlined color="color_info" type="info">
      {{ $t("app.logout") }}
    </v-alert>
  </v-container>
</template>

<script>
export default {
  name: "ComponentsLogout",
};
</script>

<style scoped></style>
