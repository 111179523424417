import * as types from "./mutation_types";
import axios from "axios";
const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

export const getItems = ({ commit }) => {
  commit(types.USER_TOGGLE_LOADING);

  axios
    .get(URL_BACKEND + "api-user/")
    .then((response) => {
      commit(types.USER_TOGGLE_LOADING);
      commit(types.USER_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.USER_TOGGLE_LOADING);
      commit(types.USER_SET_ERROR, e.message);
    });
};
