<template>
  <v-card flat>
    <v-overlay :value="isLoading()">
      <v-progress-circular indeterminate size="100" />
    </v-overlay>
    <v-card-text>
      <ap-list :aps="items" />
    </v-card-text>
  </v-card>
</template>

<script>
import { apMixin } from "@/mixins/ap";
import apList from "@/components/ap/List";
import { userMixin } from "@/mixins/user";

export default {
  name: "VueAp",
  mixins: [apMixin, userMixin],
  components: {
    apList,
  },
  methods: {
    isLoading() {
      return this.apsIsLoading || this.apIsLoading || this.usersIsLoading;
    },
  },
  mounted() {
    if (this.items.length === 0) this.getAps();
    this.getUsers();
  },
};
</script>

<style scoped></style>
