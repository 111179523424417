import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.DR_UPDATE_RESET);
};

export const update = ({ commit }, values) => {
  commit(types.DR_UPDATE_SET_ERROR, "");
  commit(types.DR_UPDATE_TOGGLE_LOADING);
  axios
    .put(URL_BACKEND + "api-dr/update/" + values.id + "/", values.values)
    .then((resultat) => {
      commit(types.DR_UPDATE_SET_UPDATED, true);
      commit(types.DR_UPDATE_TOGGLE_LOADING);
      values.item.max_date_allocation = resultat.data.max_date_allocation;
      values.item.is_decompose_gestionnaire =
        resultat.data.is_decompose_gestionnaire;
      values.item.default_statut_ap = resultat.data.default_statut_ap;
      values.item.default_statut_cockpit = resultat.data.default_statut_cockpit;
      values.item.alert_nb_jour = resultat.data.alert_nb_jour;
      values.item.warnings_nb_jour = resultat.data.warnings_nb_jour;
    })
    .catch((e) => {
      commit(types.DR_UPDATE_TOGGLE_LOADING);
      commit(types.DR_UPDATE_SET_ERROR, e.message);
    });
};
