<template>
  <v-card flat v-if="cockpit && cockpitId !== ''">
    <v-toolbar flat dark color="color_dialog">
      <v-btn color="color_action" icon @click="closeDialog()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("cockpit.edit.title") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items v-if="user.is_dr">
        <v-btn text @click="traite()" color="color_action" class="mr-5">
          <span v-if="cockpit.is_vue"> {{ $t("btn.traite") }} </span>
          <span v-else>
            {{ $t("btn.untraite") }}
          </span>
        </v-btn>
        <v-btn text @click="take()" color="color_action">
          <span v-if="cockpit.user">
            {{ $t("btn.takenby") }} {{ cockpit.user }}
          </span>
          <span v-else>
            {{ $t("btn.take") }}
          </span>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <cockpit-show :cockpit="cockpit" />
      <v-card flat>
        <show-ligne :title="$t('relance.title')">
          <v-card v-if="cockpit.sta" flat width="100%">
            <v-card-title> Ajouter un message</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-form>
                    <v-textarea
                      clearable
                      v-model="form.commentaire"
                      label="message"
                    />
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer />
                <v-btn color="color_action" @click="saveCommentaire()">
                  <v-icon left>mdi-send</v-icon>
                  {{ $t("btn.send") }}
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            flat
            v-for="(relance, index) in cockpit.relances"
            :key="index"
          >
            <v-card-title>
              {{ relance.user_name }} le {{ relance.created_at }}
            </v-card-title>
            <v-card-text>
              <div
                v-html="sanitizedTextContent(relance.commentaire)"
                class="mb-2"
              />
              <v-divider />
            </v-card-text>
          </v-card>
        </show-ligne>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { cockpitMixin } from "@/mixins/cockpit";
import cockpitShow from "@/components/cockpit/Show";
import { userMixin } from "@/mixins/user";
import { relanceMixin } from "@/mixins/relance";
import showLigne from "@/components/common/ShowLigne";
import { sanitizedTextContent } from "@/lib/common";

export default {
  name: "CockpitEdit",
  mixins: [cockpitMixin, userMixin, relanceMixin],
  components: {
    cockpitShow,
    showLigne,
  },
  data() {
    return {
      form: { typeRelance: null, commentaire: "" },
      sanitizedTextContent: sanitizedTextContent,
    };
  },
  props: {
    closeDialog: {
      type: Function,
      default: null,
    },
    cockpitId: {
      type: String,
      default: "",
    },
  },
  methods: {
    take() {
      this.takeCockpit({
        id: this.cockpitId,
        list: this.items,
        item: this.cockpit,
      });
    },
    traite() {
      this.traiteCockpit({
        id: this.cockpitId,
        list: this.items,
        item: this.cockpit,
      });
    },
    saveCommentaire() {
      this.createRelance({
        id: this.cockpitId,
        list: this.cockpit.relances,
        user_name: this.user.first_name + " " + this.user.last_name,
        values: this.form,
      });
      this.form.commentaire = "";
    },
  },
  watch: {
    cockpitId(cockpitId) {
      this.getCockpit(cockpitId);
      this.form.commentaire = "";
    },
  },
  mounted() {
    this.getCockpit(this.cockpitId);
  },
};
</script>

<style scoped></style>
