import { mapActions, mapGetters } from "vuex";

export const cockpitMixin = {
  computed: {
    ...mapGetters({
      items: "cockpit/list/items",
      cockpitAsrs: "cockpit/listAsr/items",
      cockpitAsrsIsLoading: "cockpit/listAsr/isLoading",
      cockpitsIsLoading: "cockpit/list/isLoading",
      cockpitIsLoading: "cockpit/show/isLoading",
      ajouteFileIsLoading: "cockpit/fileAdd/isLoading",
      cockpit: "cockpit/show/retrieved",
    }),
  },
  methods: {
    ...mapActions({
      getCockpits: "cockpit/list/getItems",
      getFacturesCockpit: "cockpit/list/getFactures",
      getCockpitAsrs: "cockpit/listAsr/getItems",
      getCockpit: "cockpit/show/retrieve",
      takeCockpit: "cockpit/update/update",
      traiteCockpit: "cockpit/traite/update",
      ajouteFile: "cockpit/fileAdd/add",
      supFile: "cockpit/fileAdd/remove",
    }),
  },
};
