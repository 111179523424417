import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.USER_UPDATE_RESET);
};

export const update = ({ commit }, values) => {
  commit(types.USER_UPDATE_SET_ERROR, "");
  commit(types.USER_UPDATE_TOGGLE_LOADING);
  axios
    .put(URL_BACKEND + "api-user/update/" + values.id + "/", values.values)
    .then((response) => {
      commit(types.USER_UPDATE_SET_UPDATED, true);
      commit(types.USER_UPDATE_TOGGLE_LOADING);
      const index = values.list.findIndex((value) => value.id === values.id);
      if (index > -1) {
        values.list.splice(index, 1, response.data);
      }
    })
    .catch((e) => {
      commit(types.USER_UPDATE_TOGGLE_LOADING);
      commit(types.USER_UPDATE_SET_ERROR, e.message);
    });
};
