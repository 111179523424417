import list from "./list";
import show from "./show";
import update from "./update";

export default {
  namespaced: true,
  modules: {
    list,
    show,
    update,
  },
};
