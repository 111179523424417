<template>
  <v-app id="inspire">
    <v-app-bar app color="color_header" dark>
      <img
        class="d-none d-sm-flex"
        src="../src/assets/logo.svg"
        alt="/"
        style="max-width: 300px"
      />
      <v-spacer />
      <v-btn link dark icon @click="go('home')">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-menu bottom v-if="user && user.is_active" min-width="150px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-view-grid</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link v-if="user.is_dr" @click="go('ap')">
            <v-list-item-icon>
              <v-icon>mdi-fish</v-icon>
            </v-list-item-icon>
            <v-list-item-content> {{ $t("menu.ap") }}</v-list-item-content>
          </v-list-item>
          <v-list-item link @click="go('cockpit')">
            <v-list-item-icon>
              <v-icon>mdi-image-filter-vintage</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t("menu.cockpit") }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.is_dr" link @click="go('sb')">
            <v-list-item-icon>
              <v-icon>mdi-home-modern</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t("menu.sb") }}</v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="user.is_dr && user.is_create"
            link
            @click="go('user')"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t("menu.user") }}</v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.is_option_dr" link @click="go('dr')">
            <v-list-item-icon>
              <v-icon>mdi-grass</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ $t("menu.dr") }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn link dark icon @click="go('login')" v-if="user === null">
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-menu bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-avatar color="color_avatar">{{ initial(user) }}</v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="reset()">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content
              >{{ user.first_name }} {{ user.last_name }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import jwt_decode from "jwt-decode";
import { userMixin } from "@/mixins/user";

export default {
  name: "App",
  mixins: [userMixin],
  methods: {
    initial() {
      if (this.user && this.user.is_active) {
        return this.user.first_name[0] + this.user.last_name[0];
      }
    },
    reset() {
      this.resetUser();
      this.$cookies.remove("refresh_token");
      this.$cookies.remove("access_token");
      if (this.$route.name !== "logout") this.$router.push({ name: "logout" });
    },
    go(route) {
      if (this.$route.name !== route) this.$router.push({ name: route });
    },
    ready: function () {
      const f = this.reset;
      setInterval(function () {
        const refresh_token = this.$cookies.get("refresh_token");
        if (refresh_token !== null) {
          const decoded = jwt_decode(refresh_token);
          if (Date.now() >= decoded.exp * 1000) {
            f();
          }
        }
      }, 10000);
    },
  },
  mounted() {
    this.userRetrieve();
    this.ready();
  },
};
</script>
