import * as types from "./mutation_types";
const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.RELANCE_CREATE_RESET);
};

export const create = ({ commit }, values) => {
  commit(types.RELANCE_CREATE_SET_ERROR, "");
  commit(types.RELANCE_CREATE_TOGGLE_LOADING);
  axios
    .post(URL_BACKEND + "api-relance/create/" + values.id + "/", values.values)
    .then((response) => {
      commit(types.RELANCE_CREATE_SET_CREATED, true);
      commit(types.RELANCE_CREATE_TOGGLE_LOADING);
      values.list.unshift(response.data);
      values.list[0].user_name = values.user_name;
    })
    .catch((e) => {
      commit(types.RELANCE_CREATE_TOGGLE_LOADING);
      commit(types.RELANCE_CREATE_SET_ERROR, e.message);
    });
};
