<template>
  <v-card flat v-if="user && user.is_create">
    <v-card flat>
      <v-card-text>
        <v-alert type="error" v-if="errorCreate">
          <div v-html="$t('user.errorCreate')" />
        </v-alert>
        <v-data-table
          :items="itemFiter()"
          :headers="headers"
          item-key="id"
          @dblclick:row="update"
          :search="search"
        >
          <template #[`top`]>
            <v-row>
              <v-btn color="color_action" @click="isActif = !isActif"
                >{{
                  !isActif ? "afficher les actifs" : "afficher les non actifs"
                }}
              </v-btn>
              <v-spacer />
              <v-text-field
                v-model="search"
                cockpitpend-icon="mdi-magnify"
                label="Rechercher"
                single-line
                class="mb-1"
                clearable
              />
              <v-spacer />
              <v-btn color="color_action" @click="create()">
                <v-icon left>mdi-plus</v-icon>
                {{ $t("btn.new") }}
              </v-btn>
            </v-row>
          </template>
          <template #[`body.prepend`]>
            <v-btn
              color="color_action"
              fab
              absolute
              icon
              right
              small
              @click="initSearch()"
            >
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
            <tr class="grey lighten-4">
              <td>
                <v-text-field v-model="itemSearch.nom" />
              </td>
              <td>
                <v-text-field v-model="itemSearch.email" />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.is_dr"
                  item-value="value"
                  item-text="text"
                  :items="yes_no"
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.is_create"
                  item-value="value"
                  item-text="text"
                  :items="yes_no"
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.dr"
                  item-value="value"
                  item-text="text"
                  :items="ListeDr()"
                  chips
                  deletable-chips
                  multiple
                />
              </td>
              <td>
                <v-select
                  v-model="itemSearch.sb"
                  item-value="value"
                  item-text="text"
                  :items="ListeSb()"
                  chips
                  deletable-chips
                  multiple
                />
              </td>
            </tr>
          </template>
          <template #[`item.is_dr`]="{ item }">
            <v-icon color="green" v-if="item.is_dr"
              >mdi-check-circle-outline
            </v-icon>
          </template>
          <template #[`item.last_name`]="{ item }">
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <template #[`item.drs`]="{ item }">
            {{ getNameDr(item.drs) }}
          </template>
          <template #[`item.sbs`]="{ item }">
            {{ getNameSb(item.sbs) }}
          </template>
          <template #[`item.is_create`]="{ item }">
            <v-icon color="green" v-if="item.is_create">
              mdi-check-circle-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-checkbox
              v-if="!nouveau"
              v-model="form.is_active"
              :label="$t('user.is_active')"
            />
            <v-checkbox v-model="form.is_dr" :label="$t('user.is_dr')" />
            <v-checkbox
              v-if="user.is_superuser && form.is_dr"
              v-model="form.is_create"
              :label="$t('user.isCreateUser')"
            />
            <v-text-field
              v-model="form.first_name"
              :rules="[validrules.required(form.first_name)]"
              :label="$t('user.prenom')"
            />

            <v-text-field
              v-model="form.last_name"
              :rules="[validrules.required(form.last_name)]"
              :label="$t('user.nom')"
            />
            <v-text-field
              v-model="form.email"
              :label="$t('user.email')"
              :rules="[
                validrules.required(form.email),
                validrules.email(form.email),
              ]"
              @input="
                form.username = form.email ? form.email.split('@')[0] : ''
              "
            />
            <!--            <v-text-field-->
            <!--              v-model="form.second_mail"-->
            <!--              :rules="[validrules.email(form.second_mail)]"-->
            <!--              :label="$t('user.second_mail')"-->
            <!--            />-->
            <v-autocomplete
              v-model="form.drs"
              :items="drs"
              item-value="id"
              item-text="libelle"
              multiple
              chips
              deletable-chips
              clearable
              :label="$t('user.dr')"
              :rules="[
                validrules.required(form.drs),
                validrules.requiredSelect(form.drs),
              ]"
            />

            <v-autocomplete
              v-model="form.sbs"
              :items="sbs"
              item-value="id"
              item-text="libelle"
              multiple
              deletable-chips
              chips
              clearable
              :rules="
                form.is_dr
                  ? []
                  : [
                      validrules.required(form.sbs),
                      validrules.requiredSelect(form.sbs),
                    ]
              "
              :label="
                form.is_dr ? $t('user.sbs.is_dr') : $t('user.sbs.is_not_dr')
              "
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="color_action" @click="dialog = false">
            <v-icon left> mdi-close</v-icon>
            {{ $t("btn.close") }}
          </v-btn>
          <v-spacer />
          <v-btn :disabled="!valid" color="color_action" @click="save">
            <v-icon left>mdi-send</v-icon>
            {{ $t("btn.submit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { sbMixin } from "@/mixins/sb";
import { userMixin } from "@/mixins/user";
import { drMixin } from "@/mixins/dr";
import { validrules } from "@/lib/validrules";

export default {
  name: "UserList",
  mixins: [sbMixin, userMixin, drMixin],
  data() {
    return {
      validrules: validrules,
      dialog: false,
      isActif: true,
      search: "",
      valid: false,
      item: null,
      nouveau: false,
      form: {},
      itemSearch: {
        nom: null,
        email: null,
        dr: [],
        sb: [],
        is_dr: null,
        is_create: null,
      },
      yes_no: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("user.nom"),
          align: "start",
          sortable: true,
          value: "last_name",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("user.username"),
          align: "start",
          sortable: true,
          value: "username",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("user.is_dr"),
          align: "start",
          sortable: true,
          value: "is_dr",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("user.isCreateUser"),
          align: "start",
          sortable: true,
          value: "is_create",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("user.dr"),
          align: "start",
          sortable: true,
          value: "drs",
          class: "color_thead  white--text",
        },
        {
          text: this.$t("user.sb"),
          align: "start",
          sortable: true,
          value: "sbs",
          class: "color_thead  white--text",
        },
      ];
    },
  },
  methods: {
    itemFiter() {
      if (this.usersAll.length > 0) {
        let items = this.usersAll.filter((x) => x.is_active === this.isActif);
        const search = this.itemSearch;

        if (this.itemSearch.nom !== null)
          items = items.filter((x) =>
            x.last_name.toLowerCase().includes(search.nom.toLowerCase())
          );
        if (this.itemSearch.email !== null)
          items = items.filter((x) =>
            x.username.toLowerCase().includes(search.email.toLowerCase())
          );
        if (this.itemSearch.is_dr !== null)
          items = items.filter((x) => x.is_dr === this.itemSearch.is_dr);
        if (this.itemSearch.is_create !== null)
          items = items.filter(
            (x) => x.is_create === this.itemSearch.is_create
          );
        if (this.itemSearch.sb.length > 0)
          items = items.filter((x) =>
            x.sbs.some((r) => search.sb.indexOf(r) >= 0)
          );
        if (this.itemSearch.dr.length > 0)
          items = items.filter((x) =>
            x.drs.some((r) => search.dr.indexOf(r) >= 0)
          );
        return items;
      }
      return [];
    },
    initSearch() {
      this.itemSearch = {
        ...{
          nom: null,
          email: null,
          dr: [],
          sb: [],
          is_dr: null,
          is_create: null,
        },
      };
    },
    getNameDr(item) {
      let res = [];
      const drs = this.drs;
      item.forEach(function (item) {
        const a = drs.find((x) => x.id === item);
        if (a !== undefined) res.push(a.libelle);
      });
      return res.join(", ");
    },
    ListeDr() {
      const drs = this.drs;
      return Array.from(
        new Set(this.usersAll.map((x) => x.drs.map((y) => y)[0]))
      ).map(function (x) {
        return {
          value: x,
          text: drs.find((y) => y.id === x)?.libelle,
        };
      });
    },
    ListeSb() {
      const sbs = this.sbs;
      const lines = this.usersAll.map((x) => x.sbs.map((y) => y));
      let a = [];
      lines.forEach((x) => x.forEach((y) => a.push(y)));
      const tab = Array.from(new Set(a));
      return tab.map(function (x) {
        return {
          value: x,
          text: sbs.find((y) => y.id === x)?.libelle,
        };
      });
    },
    getNameSb(item) {
      let res = [];
      const sbs = this.sbs;
      item.forEach(function (item) {
        const a = sbs.find((x) => x.id === item);
        if (a !== undefined) res.push(a.libelle);
      });
      return res.join(", ");
    },
    resetValues() {
      this.form = {
        is_active: true,
        first_name: "",
        last_name: "",
        is_create: false,
        email: "",
        is_dr: false,
        drs: [],
        sbs: [],
        username: "",
      };
    },
    create() {
      this.resetValues();
      this.nouveau = true;
      this.dialog = true;
    },
    update(event, { item }) {
      this.nouveau = false;
      this.item = item;
      this.form = {
        drs: item.drs,
        sbs: item.sbs,
        is_dr: item.is_dr,
        is_active: item.is_active,
        email: item.email,
        first_name: item.first_name,
        last_name: item.last_name,
        is_create: item.is_create,
        username: item.username,
      };
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    save() {
      if (this.nouveau === true) {
        this.setUser({
          list: this.usersAll,
          values: this.form,
        });
      } else {
        this.updateUser({
          list: this.usersAll,
          id: this.item.id,
          values: this.form,
        });
      }
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
