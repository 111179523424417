import { getColorInteret } from "@/lib/common";
import { getFiltered } from "@/lib/libList";

export const listMixins = {
  data() {
    return {
      factureSearch: "",
      menu: false,
      getColorInteret: getColorInteret,
      dialog: false,
      itemId: null,
      gestionnaires: null,
      search: "",
      toExcel: null,
      itemSearch: {
        sb: "",
        dr: "",
        raison_social_four: "",
        statut_fact: {
          ap: [],
          cockpit: [],
        },
        type_fact: "",
        numero_facture: "",
        montant_ht_min: null,
        montant_ht_max: null,
        commande: "",
        responsable_action: "",
        acheteur: "",
        date_reception_fact: [],
        alerte: "",
        asr: false,
        user: "",
        ged: false,
        old: false,
        relance: "",
        contrat_dates: null,
        maxInteret: null,
        action: "",
        compte_comptable: "",
      },
    };
  },
  computed: {
    ListeOuiNon() {
      return [
        { text: "Dans les temps", value: 1 },
        { text: "Proche fin de contrat", value: 2 },
        { text: "Fin de contrat dépassée", value: 3 },
        { text: "Facture hors contrat", value: 0 },
        { text: "Facture sans commande", value: -1 },
        { text: "Tous", value: null },
      ];
    },
    ListeTypeAlerte() {
      return ["Rouge", "Orange", "Vert", "Violet", "Tous"];
    },
    ListeRelance() {
      return [
        { value: "SB", text: "En attente de Retour" },
        { value: "DR", text: "Retour SB" },
        { value: "VIDE", text: "Aucune relance" },
      ];
    },
    likesAllGest() {
      return this.gestionnaires?.length === this.users?.length;
    },
    likesSomeGest() {
      return this.gestionnaires?.length > 0 && !this.likesAllGest;
    },
    icon() {
      if (this.likesAllGest) return "mdi-close-box";
      if (this.likesSomeGest) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    toggle() {
      if (this.gestionnaires?.length === this.users?.length)
        this.gestionnaires = [];
      else this.gestionnaires = this.users.map((x) => x.id);
    },
    itemRowBackground: function (item) {
      if (item.is_vue) return "green lighten-5";
      return item.id === this.itemId ? "indigo lighten-5" : "";
    },
    chargeUser() {
      this.getitems(this.gestionnaires);
    },
    itemsFiltered() {
      if (this.user)
        return getFiltered(this.items, this.itemSearch, this.type, {
          DANGER: this.user.alert_nb_jour,
          WARNING: this.user.warnings_nb_jour,
          DEAD: this.user.im_nb_jour,
        });
      return [];
    },
    ListeStatutFac() {
      return this.items.map((x) => x.statut_fact);
    },
    ListeTypeFac() {
      return this.items.map((x) => x.type_fact);
    },
    Listecompte_comptable() {
      let a = this.items.map((x) => x.compte_comptable).sort();
      a.push("Vide");
      return a;
    },

    ListeUser() {
      return this.items.map((x) => x.user);
    },
    ListeSb() {
      return this.items.map((x) => x.sb);
    },
    ListeDr() {
      return this.items.map((x) => x.dr);
    },

    initSearch() {
      this.search = "";
      this.gestionnaires = null;
      this.itemSearch = {
        sb: "",
        dr: "",
        ged: false,
        old: false,
        raison_social_four: "",
        statut_fact: {
          ap: [],
          cockpit: [],
        },
        type_fact: "",
        numero_facture: "",
        montant_ht_min: null,
        montant_ht_max: null,
        commande: "",
        responsable_action: "",
        acheteur: "",
        date_reception_fact: [],
        alerte: "",
        asr: false,
        user: "",
        relance: "",
        contrat_dates: null,
        maxInteret: null,
        action: "",
        compte_comptable: "",
      };
    },
    openDialog(event, { item }) {
      this.itemId = item.id;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    user(nouveau) {
      let a = this;
      nouveau.default_statut_ap[0]?.forEach(function (item) {
        a.itemSearch.statut_fact.ap.push(item);
      });
      nouveau.default_statut_cockpit[0]?.forEach(function (item) {
        a.itemSearch.statut_fact.cockpit.push(item);
      });
    },
  },
  mounted() {
    if (this.user) {
      let a = this;
      this.user.default_statut_ap[0]?.forEach(function (item) {
        a.itemSearch.statut_fact.ap.push(item);
      });
      this.user.default_statut_cockpit[0]?.forEach(function (item) {
        a.itemSearch.statut_fact.cockpit.push(item);
      });
    }
  },
};
