<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md4 offset-md-3>
        <v-card class="elevation-12 mb-12" style="top: 20vh">
          <v-toolbar dark color="color_header">
            <v-toolbar-title>{{ $t("login.form.title") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert outlined v-if="error" type="error">{{
              $t("login.error")
            }}</v-alert>
            <v-form>
              <v-text-field
                v-model="auth.username"
                prepend-icon="mdi-account"
                :label="$t('login.form.login')"
                type="text"
              />
              <v-text-field
                v-model="auth.password"
                prepend-icon="mdi-lock"
                :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="visible = !visible"
                :label="$t('login.form.password')"
                :type="visible ? 'text' : 'password'"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="color_action" @click="connexion()"
              >{{ $t("btn.connect") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

export default {
  name: "VueLogin",
  data() {
    return {
      auth: {
        username: null,
        password: null,
      },
      visible: false,
      error: false,
    };
  },
  methods: {
    ...mapActions({
      setUser: "user/current/retrieve",
    }),
    connexion() {
      this.error = false;
      axios
        .post(URL_BACKEND + "api-token/", this.auth)
        .then((response) => {
          this.$cookies.set("access_token", response.data.access);
          this.$cookies.set("refresh_token", response.data.refresh);
          this.$router.push({ name: "home" });
          this.setUser();
        })
        .catch(() => {
          this.auth.password = null;
          this.error = true;
        });
    },
  },
};
</script>

<style scoped>
.container.fill-height {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  background: url("../../src/assets/fond.jpeg") no-repeat;
}
</style>
