<template>
  <v-container class="mt-3">
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md6>
        <v-row>
          <v-img src="../assets/logo.png" width="125" />
        </v-row>
      </v-flex>
    </v-layout>
    <v-container fluid>
      <v-row class="mt-10 text-justify">
        <p>
          Le programme "<b>Suivi de Gestion</b>" (SUIGE) est un outil
          d'accompagnement des personnels des services financiers et comptables
          dans l'appropriation des actes de gestion dématérialisés et
          l'adaptation des méthodes de travail.
        </p>
        <p>
          Il complète les dispositifs déjà existants ou à venir de
          simplification et de modernisation des processus à l'Inserm.
        </p>
        <p>
          <b>Suivi de Gestion</b> permet aux gestionnaires d'identifier, classer
          et organiser leur travail au quotidien, et d'échanger facilement et
          rapidement avec les laboratoires, et aux encadrants de mieux apprécier
          l’évolution de leur activité au jour le jour.
        </p>
        <p>
          <b>Suivi de Gestion</b> est alimenté par les saisies des utilisateurs
          dans SAFIr. Plus ces saisies sont complètes et précises plus le
          programme sera performant et efficace.
        </p>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "VueHome",
};
</script>

<style scoped></style>
