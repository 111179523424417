import { mapActions, mapGetters } from "vuex";

export const relanceMixin = {
  computed: {
    ...mapGetters({
      relances: "relance/list/items",
      relanceTypes: "typeRelance/list/items",
      relanceTypesIsLoading: "typeRelance/list/isLoading",
      relanceIsLoading: "relance/list/isLoading",
      relanceCreateIsLoading: "relance/create/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      getTypeRelances: "typeRelance/list/getItems",
      getRelance: "relance/list/getItems",
      createRelance: "relance/create/create",
    }),
  },
};
