import axios from "axios";
import Vue from "vue";
import userStore from "@/store/user/current";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

axios.interceptors.request.use(
  (config) => {
    if (Vue.$cookies.get("access_token")) {
      config.headers["Authorization"] =
        "Bearer " + Vue.$cookies.get("access_token");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const path = new URL(error.config.url).pathname;
    const originalRequest = error.config;
    if (path.endsWith("/api-token/refresh/")) {
      Vue.$cookies.remove("refresh_token");
      Vue.$cookies.remove("access_token");
    } else if (400 <= error.response.status < 500 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(URL_BACKEND + "api-token/refresh/", {
          refresh: Vue.$cookies.get("refresh_token"),
        })
        .then((res) => {
          if (res.status === 200) {
            Vue.$cookies.set("access_token", res.data.access);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + Vue.$cookies.get("access_token");
            return axios(originalRequest);
          }
        })
        .catch(() => {
          userStore.state.retrieved = null;
        });
    }
    return Promise.reject(error);
  }
);

export default axios;
