export function getFiltered(items, itemSearch, type, tmp_alerte) {
  if (type === "COCKPIT") {
    if (itemSearch.asr && itemSearch.asr === true) {
      items = items.filter((x) => x.action.indexOf("AVP en cours") > -1);
    }

    if (itemSearch.action && itemSearch.action.trim() !== "") {
      items = items.filter((x) => x.action && x.action === itemSearch.action);
    }
    if (itemSearch.statut_fact.cockpit.length > 0) {
      items = items.filter(
        (x) =>
          x.statut_fact &&
          itemSearch.statut_fact.cockpit.find(
            (elem) => elem === x.statut_fact
          ) !== undefined
      );
    }
  }
  if (type === "AP") {
    if (itemSearch.ged) {
      items = items.filter((x) => x.is_ged);
    }
    if (itemSearch.old) {
      items = items.filter((x) => x.is_old);
    }
    if (itemSearch.statut_fact.ap.length > 0) {
      items = items.filter(
        (x) =>
          x.statut_fact &&
          itemSearch.statut_fact.ap.find((elem) => elem === x.statut_fact) !==
            undefined
      );
    }
  }

  if (itemSearch.compte_comptable !== "") {
    if (itemSearch.compte_comptable === "Vide") {
      items = items.filter(
        (x) => !x.compte_comptable || x.compte_comptable === ""
      );
    } else
      items = items.filter(
        (x) =>
          x.compte_comptable &&
          x.compte_comptable === itemSearch.compte_comptable
      );
  }
  if (itemSearch.dr !== "")
    items = items.filter((x) => x.dr && x.dr === itemSearch.dr);

  if (itemSearch.sb !== "")
    items = items.filter((x) => x.sb && x.sb === itemSearch.sb);

  if (itemSearch.raison_social_four !== "")
    items = items.filter(
      (x) =>
        x.raison_social_four &&
        x.raison_social_four
          .toLowerCase()
          .includes(itemSearch.raison_social_four.toLowerCase())
    );

  if (itemSearch.type_fact !== "")
    items = items.filter(
      (x) => x.type_fact && x.type_fact === itemSearch.type_fact
    );

  if (itemSearch.numero_facture !== "")
    items = items.filter(
      (x) =>
        x.numero_facture &&
        x.numero_facture
          .toLowerCase()
          .includes(itemSearch.numero_facture.toLowerCase())
    );

  if (itemSearch.commande !== "")
    items = items.filter(
      (x) =>
        (x.commande &&
          x.commande
            .toLowerCase()
            .includes(itemSearch.commande.toLowerCase())) ||
        (x.commande_corrige &&
          x.commande_corrige
            .toLowerCase()
            .includes(itemSearch.commande.toLowerCase()))
    );

  if (itemSearch.responsable_action !== "")
    items = items.filter(
      (x) =>
        x.responsable_action &&
        x.responsable_action
          .toLowerCase()
          .includes(itemSearch.responsable_action.toLowerCase())
    );

  if (itemSearch.acheteur !== "")
    items = items.filter(
      (x) =>
        x.acheteur &&
        x.acheteur.toLowerCase().includes(itemSearch.acheteur.toLowerCase())
    );

  if (itemSearch.user !== "")
    items = items.filter((x) => x.user && x.user === itemSearch.user);

  if (itemSearch.relance !== "")
    items = items.filter((x) => x.relance && x.relance === itemSearch.relance);

  if (itemSearch.montant_ht_min !== null) {
    items = items.filter(
      (x) => x.montant_ht && x.montant_ht >= itemSearch.montant_ht_min
    );
  }

  if (itemSearch.montant_ht_max !== null) {
    items = items.filter(
      (x) => x.montant_ht && x.montant_ht < itemSearch.montant_ht_max
    );
  }

  if (itemSearch.maxInteret !== null) {
    items = items.filter(
      (x) => x.interet && x.interet >= itemSearch.maxInteret
    );
  }

  if (itemSearch.contrat_dates !== null) {
    if (itemSearch.contrat_dates === -1)
      items = items.filter(
        (x) => x.commande === null && x.commande_corrige === null
      );
    else {
      items = items.filter(
        (x) =>
          x.alerte_alloc_commande !== null &&
          x.alerte_alloc_commande === itemSearch.contrat_dates
      );
    }
  }
  if (itemSearch.alerte !== null && itemSearch.alerte !== "Tous") {
    switch (itemSearch.alerte) {
      case "Violet":
        items = items.filter(
          (x) => x.interet !== null && x.interet >= tmp_alerte.DEAD
        );
        break;
      case "Rouge":
        items = items.filter(
          (x) =>
            x.interet !== null &&
            x.interet >= tmp_alerte.DANGER[x.dr] &&
            x.interet < tmp_alerte.DEAD
        );
        break;
      case "Orange":
        items = items.filter(
          (x) =>
            x.interet !== null &&
            x.interet >= tmp_alerte.WARNING[x.dr] &&
            x.interet < tmp_alerte.DANGER[x.dr]
        );
        break;
      case "Vert":
        items = items.filter(
          (x) => x.interet !== null && x.interet < tmp_alerte.WARNING[x.dr]
        );
        break;
    }
  }
  if (itemSearch.date_reception_fact.length > 0) {
    if (itemSearch.date_reception_fact.length === 1) {
      items = items.filter(
        (x) =>
          x.date_reception_fact &&
          x.date_reception_fact === itemSearch.date_reception_fact[0]
      );
    }
    if (itemSearch.date_reception_fact.length === 2) {
      let tmp_aray = [
        itemSearch.date_reception_fact[1],
        itemSearch.date_reception_fact[0],
      ].sort();
      items = items.filter(
        (x) =>
          x.date_reception_fact &&
          x.date_reception_fact >= tmp_aray[0] &&
          x.date_reception_fact <= tmp_aray[1]
      );
    }
  }
  return items;
}
