import DOMPurify from "dompurify";

export function digramme(first, last) {
  return first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase();
}

export function getColorInteret(val, dr, alerte) {
  if (val >= alerte.DEAD) {
    return "purple white--text font-weight-bold";
  }
  if (val >= alerte.DANGER[dr]) {
    return "pink darken-2 white--text font-weight-bold";
  }
  if (val >= alerte.WARNING[dr]) {
    return "orange darken-2 white--text font-weight-bold";
  }
  return "green darken-2 white--text font-weight-bold";
}

export function sanitizedTextContent(text) {
  return DOMPurify.sanitize(text);
}
