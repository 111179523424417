<template>
  <v-card flat>
    <v-overlay :value="isLoading()">
      <v-progress-circular indeterminate size="100" />
    </v-overlay>
    <v-card-text>
      <list />
    </v-card-text>
  </v-card>
</template>

<script>
import { sbMixin } from "@/mixins/sb";
import { userMixin } from "@/mixins/user";
import { drMixin } from "@/mixins/dr";
import List from "@/components/user/List";

export default {
  name: "StructureVue",
  mixins: [sbMixin, userMixin, drMixin],
  components: {
    List,
  },
  methods: {
    isLoading() {
      return (
        this.sbIsLoading ||
        this.usersAllIsLoading ||
        this.drIsLoading ||
        this.userCreateIsLoading ||
        this.userUpdateIsLoading
      );
      // );
    },
  },
  mounted() {
    this.getSbs();
    this.getDrs();
    this.getAllUsers();
  },
};
</script>

<style scoped></style>
