<template>
  <v-col sm="12">
    <v-card flat>
      <show-ligne
        :title="
          corrige
            ? $t('commande.show.commande_corrige')
            : $t('commande.show.commande')
        "
        :sub="true"
      >
        <v-container fluid>
          <v-simple-table>
            <thead class="color_thead white--text">
              <tr class="">
                <th class="text-left white--text">
                  {{ $t("commande.numero") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("commande.acheteur") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.sb") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.compte_comptable") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.projet_num") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.allocation_num") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.date_fin_projet") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.date_fin_allocation") }}
                </th>
                <th class="text-left white--text">
                  {{ $t("contrat.show.date_limite_engagement") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getElems(commande)" :key="index">
                <!--                <td>-->
                <!--                  <a target="_blank" :href="getUrlGed(commande)">{{-->
                <!--                    item.numero-->
                <!--                  }}</a>-->
                <!--                </td>-->
                <td>{{ item.numero }}</td>
                <td>{{ item.acheteur }}</td>
                <td>{{ item.sb }}</td>
                <td>{{ item.compte_comptable }}</td>
                <td>{{ item.projet_num }}</td>
                <td>{{ item.allocation_num }}</td>
                <td>{{ item.date_fin_projet }}</td>
                <td>{{ item.date_fin_allocation }}</td>
                <td>{{ item.date_limite_engagement }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-container>
      </show-ligne>
    </v-card>
  </v-col>
</template>

<script>
import ShowLigne from "@/components/common/ShowLigne";
import { URLGED } from "@/lib/variables";
// import { URLGED } from "@/lib/variables";

export default {
  name: "RetrivedVue",
  components: {
    ShowLigne,
  },
  props: {
    commande: {
      type: Object,
      default: null,
    },
    corrige: {
      type: Boolean,
      default: false,
    },
    structure: {
      type: String,
      default: "",
    },
  },
  computed: {
    keys_col1() {
      return [
        {
          value: "numero",
          text: this.$i18n.t("commande.show.numero"),
          class: "odd",
        },
      ];
    },
  },
  methods: {
    getUrlGed(commande) {
      return (
        URLGED +
        "/commande?commande__structure=" +
        commande.numero +
        "__" +
        this.structure
      );
    },

    getElems(commande) {
      let a = this.commande.ligne_commandes.map((x) =>
        x.contrats.length > 0
          ? x.contrats.map((c) => ({
              numero: commande.numero,
              acheteur: commande.acheteur,
              compte_comptable: x.compte_comptable,
              sb: c.sb,
              projet_num: c.projet_num,
              allocation_num: c.allocation_num,
              date_fin_projet: c.date_fin_projet,
              date_fin_allocation: c.date_fin_allocation,
              date_limite_engagement: c.date_limite_engagement,
            }))
          : {
              numero: commande.numero,
              acheteur: commande.acheteur,
              compte_comptable: x.compte_comptable,
            }
      );
      let uniqueObjArray = [
        ...new Map(
          a
            .flat()
            .map((item) => [
              item["sb"] +
                "_" +
                item["allocation_num"] +
                "_" +
                item["projet_num"] +
                "_" +
                item["compte_comptable"],
              item,
            ])
        ).values(),
      ];
      return uniqueObjArray;
    },
  },
};
</script>

<style scoped></style>
