<template>
  <v-card flat v-if="cockpit">
    <v-alert v-if="cockpit.alerte_alloc_commande" type="warning" class="mt-2">
      {{ $t("cockpit.alerte_alloc_commande") }}
    </v-alert>
    <show-ligne :title="$t('cockpit.show.description')">
      <v-container fluid>
        <v-row>
          <show-col :elem="cockpit" :keys_col="keys_col1" />
          <show-col :elem="cockpit" :keys_col="keys_col2" />
        </v-row>
      </v-container>
    </show-ligne>
    <show-ligne :title="$t('cockpit.show.action')">
      <v-container fluid>
        <v-row>
          <v-col
            >{{ cockpit.responsable_action }} ({{ cockpit.date_action }})
          </v-col>
        </v-row>
        <v-row>
          <v-col>{{ cockpit.action }} </v-col>
        </v-row>
        <v-row>
          <v-col>{{ cockpit.comment_action }}</v-col>
        </v-row>
      </v-container>
    </show-ligne>
    <show-ligne :title="$t('cockpit.show.commande')">
      <v-container fluid>
        <v-row>
          <retrieved
            v-if="cockpit.commande"
            :commande="cockpit.commande"
            :structure="cockpit.sb"
            :corrige="false"
          />
        </v-row>
        <v-row>
          <retrieved
            v-if="cockpit.commande_corrige"
            :commande="cockpit.commande_corrige"
            :structure="cockpit.sb"
            :corrige="true"
          />
        </v-row>
      </v-container>
    </show-ligne>

    <show-ligne :title="$t('cockpit.show.files')">
      <cockpit-file :item="cockpit" />
    </show-ligne>
  </v-card>
</template>

<script>
import ShowCol from "@/components/common/ShowCol";
import ShowLigne from "@/components/common/ShowLigne";
import CockpitFile from "@/components/cockpit/File";
import Retrieved from "@/components/commande/Retrieved";

export default {
  name: "CockpitShow",
  components: {
    ShowCol,
    ShowLigne,
    Retrieved,
    CockpitFile,
  },

  computed: {
    keys_col1() {
      return [
        { value: "dr", text: this.$i18n.t("cockpit.show.dr"), class: "odd" },
        {
          value: "statut_fact",
          text: this.$i18n.t("cockpit.show.statut_fact"),
          class: "pair",
        },
        {
          value: "numero_facture",
          text: this.$i18n.t("cockpit.show.numero_facture"),
          class: "odd",
        },
        {
          value: "date_fact",
          text: this.$i18n.t("cockpit.show.date_fact"),
          class: "pair",
        },
        {
          value: "montant_ht",
          text: this.$i18n.t("cockpit.show.montant_ht"),
          class: "odd",
        },
        {
          value: "responsable_action",
          text: this.$i18n.t("cockpit.show.responsable_action"),
          class: "pair",
        },
      ];
    },
    keys_col2() {
      return [
        { value: "sb", text: this.$i18n.t("cockpit.show.sb"), class: "odd" },
        {
          value: "type_fact",
          text: this.$i18n.t("cockpit.show.type_fact"),
          class: "pair",
        },
        {
          value: "raison_social_four",
          text: this.$i18n.t("cockpit.show.raison_social_four"),
          class: "odd",
        },
        {
          value: "date_reception_fact",
          text: this.$i18n.t("cockpit.show.date_reception_fact"),
          class: "pair",
        },
        {
          value: "montant_total",
          text: this.$i18n.t("cockpit.show.montant_total"),
          class: "odd",
        },
        {
          value: "date_action",
          text: this.$i18n.t("cockpit.show.date_action"),
          class: "pair",
        },
      ];
    },
  },
  props: {
    cockpit: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
