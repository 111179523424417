import { mapActions, mapGetters } from "vuex";

export const apMixin = {
  computed: {
    ...mapGetters({
      items: "ap/list/items",
      apAsrs: "ap/listAsr/items",
      apAsrsIsLoading: "ap/listAsr/isLoading",
      apsIsLoading: "ap/list/isLoading",
      apIsLoading: "ap/show/isLoading",
      ap: "ap/show/retrieved",
    }),
  },
  methods: {
    ...mapActions({
      getAps: "ap/list/getItems",
      getFacturesAp: "ap/list/getFactures",
      getApAsrs: "ap/listAsr/getItems",
      getAp: "ap/show/retrieve",
      takeAp: "ap/update/update",
      traiteAp: "ap/traite/update",
    }),
  },
};
