import * as types from "./mutation_types";
import axios from "@/lib/axioxAppli";
const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

export const getItems = ({ commit }) => {
  commit(types.DR_TOGGLE_LOADING);

  axios
    .get(URL_BACKEND + "api-dr/")
    .then((response) => {
      commit(types.DR_TOGGLE_LOADING);
      commit(types.DR_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.DR_TOGGLE_LOADING);
      commit(types.DR_SET_ERROR, e.message);
    });
};
