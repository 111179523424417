<template>
  <v-card flat>
    <v-overlay :value="isLoading()">
      <v-progress-circular indeterminate size="100" />
    </v-overlay>
    <v-card-text>
      <dr-list />
    </v-card-text>
  </v-card>
</template>

<script>
import { drMixin } from "@/mixins/dr";
import drList from "@/components/dr/List";

export default {
  name: "DrVue",
  mixins: [drMixin],

  components: { drList },
  methods: {
    isLoading() {
      return this.updateDrIsLoading || this.drIsLoading;
    },
  },
  mounted() {
    this.getDrs();
  },
};
</script>

<style scoped></style>
