<template>
  <v-card flat v-if="ap">
    <v-alert v-if="ap.is_old" type="warning" class="mt-2">
      {{ $t("ap.old") }}
    </v-alert>
    <v-alert v-if="ap.is_ged" type="warning" class="mt-2">
      {{ $t("ap.ged") }}
    </v-alert>
    <show-ligne :title="$t('ap.show.description')">
      <v-container fluid>
        <v-row>
          <show-col :elem="ap" :keys_col="keys_col1" />
          <show-col :elem="ap" :keys_col="keys_col2" />
        </v-row>
      </v-container>
    </show-ligne>
    <show-ligne :title="$t('ap.show.commande')">
      <v-container fluid>
        <v-row>
          <retrieved
            v-if="ap.commande"
            :commande="ap.commande"
            :structure="ap.sb"
            :corrige="false"
          />
        </v-row>
        <v-row>
          <retrieved
            v-if="ap.commande_corrige"
            :commande="ap.commande_corrige"
            :structure="ap.sb"
            :corrige="true"
          />
        </v-row>
      </v-container>
    </show-ligne>
  </v-card>
</template>

<script>
import ShowCol from "@/components/common/ShowCol";
import ShowLigne from "@/components/common/ShowLigne";
import Retrieved from "@/components/commande/Retrieved";

export default {
  name: "ApShow",
  components: {
    ShowCol,
    ShowLigne,
    Retrieved,
  },

  computed: {
    keys_col1() {
      return [
        { value: "dr", text: this.$i18n.t("ap.show.dr"), class: "odd" },
        {
          value: "statut_fact",
          text: this.$i18n.t("ap.show.statut_fact"),
          class: "pair",
        },
        {
          value: "numero_facture",
          text: this.$i18n.t("ap.show.numero_facture"),
          class: "odd",
        },
        {
          value: "date_fact",
          text: this.$i18n.t("ap.show.date_fact"),
          class: "pair",
        },
        {
          value: "montant_ht",
          text: this.$i18n.t("ap.show.montant_ht"),
          class: "odd",
        },
        {
          value: "responsable_action",
          text: this.$i18n.t("ap.show.responsable_action"),
          class: "pair",
        },
      ];
    },
    keys_col2() {
      return [
        { value: "sb", text: this.$i18n.t("ap.show.sb"), class: "odd" },
        {
          value: "type_fact",
          text: this.$i18n.t("ap.show.type_fact"),
          class: "pair",
        },
        {
          value: "raison_social_four",
          text: this.$i18n.t("ap.show.raison_social_four"),
          class: "odd",
        },
        {
          value: "date_reception_fact",
          text: this.$i18n.t("ap.show.date_reception_fact"),
          class: "pair",
        },
        {
          value: "montant_total",
          text: this.$i18n.t("ap.show.montant_total"),
          class: "odd",
        },
        {
          value: "date_action",
          text: this.$i18n.t("ap.show.date_action"),
          class: "pair",
        },
      ];
    },
  },
  props: {
    ap: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped></style>
