<template>
  <v-card flat>
    <v-overlay :value="isLoading()">
      <v-progress-circular indeterminate size="100" />
    </v-overlay>
    <v-card-text>
      <list />
    </v-card-text>
  </v-card>
</template>

<script>
import { sbMixin } from "@/mixins/sb";
import { userMixin } from "@/mixins/user";
import List from "@/components/sb/List";

export default {
  name: "StructureVue",
  mixins: [sbMixin, userMixin],
  components: {
    List,
  },
  methods: {
    isLoading() {
      return this.sbIsLoading || this.usersIsLoding || this.usersEmailIsLoading;
    },
  },
  mounted() {
    this.getSbs();
    this.getEmailUsers();
  },
};
</script>

<style scoped></style>
