import * as types from "./mutation_types";
import axios from "@/lib/axioxAppli";
const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;

export const getItems = ({ commit }, values) => {
  commit(types.AP_TOGGLE_LOADING);
  axios
    .get(URL_BACKEND + "api-ap/", { params: values })
    .then((response) => {
      commit(types.AP_TOGGLE_LOADING);
      commit(types.AP_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.AP_TOGGLE_LOADING);
      commit(types.AP_SET_ERROR, e.message);
    });
};

export const getFactures = ({ commit }, values) => {
  commit(types.AP_TOGGLE_LOADING);
  axios
    .get(URL_BACKEND + "api-ap/search/", { params: values })
    .then((response) => {
      commit(types.AP_TOGGLE_LOADING);
      commit(types.AP_SET_ITEMS, response.data);
    })
    .catch((e) => {
      commit(types.AP_TOGGLE_LOADING);
      commit(types.AP_SET_ERROR, e.message);
    });
};
