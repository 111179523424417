import * as types from "./mutation_types";

const URL_BACKEND = process.env.VUE_APP_URL_BACKEND;
import axios from "@/lib/axioxAppli";

export const reset = ({ commit }) => {
  commit(types.SB_UPDATE_RESET);
};

export const update = ({ commit }, values) => {
  commit(types.SB_UPDATE_SET_ERROR, "");
  commit(types.SB_UPDATE_TOGGLE_LOADING);
  axios
    .put(URL_BACKEND + "api-sb/update/" + values.id + "/", values.values)
    .then((response) => {
      commit(types.SB_UPDATE_SET_UPDATED, true);
      commit(types.SB_UPDATE_TOGGLE_LOADING);
      const rep = response.data;
      const objIndex = values.sbs.findIndex((obj) => obj.id === values.id);
      let d = [];
      rep.gestionnaire_drs.forEach(function (item) {
        let user = values.users.find((x) => x.id === item);
        d.push(user);
      });
      let l = [];
      rep.gestionnaire_labos.forEach(function (item) {
        let user = values.users.find((x) => x.id === item);
        l.push(user);
      });
      values.sbs[objIndex].gestionnaire_drs = d;
      values.sbs[objIndex].gestionnaire_labos = l;
    })
    .catch((e) => {
      commit(types.SB_UPDATE_TOGGLE_LOADING);
      commit(types.SB_UPDATE_SET_ERROR, e.message);
    });
};
