import list from "./list";
import listAsr from "./listAsr";
import show from "./show";
import update from "./update";
import traite from "./traite";
import fileAdd from "./fileAdd";

export default {
  namespaced: true,
  modules: {
    list,
    show,
    listAsr,
    update,
    fileAdd,
    traite,
  },
};
