import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import fr from "vuetify/es5/locale/fr";

import "@mdi/font/css/materialdesignicons.css";

import "../sass/style.sass";
import "fontsource-raleway/400.css";
import "fontsource-raleway/400-italic.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        color_header: "#50BFB0",
        color_thead: "#84A5DC",
        color_dialog: "#3E67AB",

        color_action: "#f4bc2e",

        color_avatar: "#49b3fd",
        color_info: "#25509B",

        color_head_dialog: "#599fc5",

        color_primary_0: "#50BFB0", // Main Primary color */
        color_primary_1: "#ADEEE5",
        color_primary_2: "#79D8CB",
        color_primary_3: "#30A393",
        color_primary_4: "",

        color_secondary_1_0: "#5D84C5", // Main Secondary color (1) */
        color_secondary_1_1: "#B5CAEF",
        color_secondary_1_2: "#84A5DC",
        color_secondary_1_3: "#3E67AB",
        color_secondary_1_4: "#25509B",

        color_secondary_2_0: "#76E35F", // Main Secondary color (2) */
        color_secondary_2_1: "#C0F7B4",
        color_secondary_2_2: "#97EE85",
        color_secondary_2_3: "#5AD640",
        // $color_secondary-2-4: #3BC31F;
      },
    },
  },
  lang: {
    locales: { fr },
    current: "fr",
  },
  icons: {
    iconfont: "mdi",
  },
});
