<template>
  <v-card flat>
    <v-card-title :class="sub ? 'text-h5' : 'text-h4'">
      {{ title }}
    </v-card-title>
    <v-card-text><slot></slot></v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CommmonShowLigne",

  props: {
    nbCol: {
      type: Number,
      default: 6,
    },
    title: {
      type: String,
      default: null,
    },
    sub: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
