import { mapActions, mapGetters } from "vuex";

export const userMixin = {
  computed: {
    ...mapGetters({
      user: "user/current/retrieved",
      usersEmail: "user/listEmail/items",
      createdUser: "user/create/created",
      updatedUser: "user/update/updated",
      users: "user/list/items",
      usersAll: "user/listAll/items",
      usersEmailIsLoading: "user/listEmail/isLoading",
      usersIsLoading: "user/list/isLoading",
      usersAllIsLoading: "user/listAll/isLoading",
      userCreateIsLoading: "user/create/isLoading",
      userUpdateIsLoading: "user/update/isLoading",
      errorCreate: "user/create/error",
    }),
  },
  methods: {
    ...mapActions({
      setUser: "user/create/create",
      updateUser: "user/update/update",
      getUsers: "user/list/getItems",
      getAllUsers: "user/listAll/getItems",
      getEmailUsers: "user/listEmail/getItems",
      userRetrieve: "user/current/retrieve",
      resetUser: "user/current/reset",
    }),
  },
};
